import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { specialtySelectors, SpecialtyState } from './specialty.entity';
import { selectSpecialtyState } from './specialty.state';

export const selectSpecialty = createSelector(selectSpecialtyState, specialtySelectors.selectAll);

export const selectCatError = createSelector(
	selectSpecialtyState,
	(state: SpecialtyState): ErrorMessage => state.error
);
