<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
	<img id="dialogLogoDN" (click)="onExit()" [src]="infoLogoDn" [alt]="'logo'" />
	<div class="dialog-title">{{ 'dottnet.button.changePassword' | translate }}</div>
	<button mat-icon-button (click)="onExit()">
		<fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
	</button>
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
	<button mat-icon-button (click)="onExit()">
		<fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
	</button>
	<div class="dialog-title">{{ 'dottnet.button.changePassword' | translate }}</div>
	<img id="dialogLogoDN" (click)="onExit()" [src]="infoLogoDn" [alt]="'logo'" />
</div>

<hr class="dialog-title-separator" />

<mat-dialog-content>
	<form class="form-container" [formGroup]="changePwdForm" (ngSubmit)="updatePassword()">
		<div class="flex-col flex-justify-center flex-align-center password-fields-wrapper">
			<mat-form-field class="large-field">
				<mat-label for="oldPassword">{{ 'dottnet.label.oldPassword' | translate }}</mat-label>
				<input
					type="password"
					matInput
					formControlName="oldPassword"
					placeholder="{{ 'dottnet.label.oldPassword' | translate }}"
				/>
				<mat-error>{{ errorMessage('oldPassword') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="large-field">
				<mat-label for="newPassword">{{ 'dottnet.label.newPassword' | translate }}</mat-label>
				<input
					type="password"
					(blur)="prova()"
					matInput
					formControlName="newPassword"
					placeholder="{{ 'dottnet.label.newPassword' | translate }}"
				/>

				<mat-error>{{ errorMessage('newPassword') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="large-field">
				<mat-label for="confirmNewPassword">{{
					'dottnet.label.confirmNewPassword' | translate
				}}</mat-label>
				<input
					type="password"
					matInput
					formControlName="confirmNewPassword"
					placeholder="{{ 'dottnet.label.confirmNewPassword' | translate }}"
				/>

				<mat-error>{{ errorMessage('confirmNewPassword') }}</mat-error>
			</mat-form-field>

			<button
				class="large-field"
				[disabled]="!changePwdForm.valid"
				type="submit"
				mat-raised-button
				color="accent"
			>
				{{ 'dottnet.button.save' | translate }}
			</button>
		</div>
	</form>
</mat-dialog-content>
