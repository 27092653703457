import { createFeature, createFeatureSelector } from '@ngrx/store';
import { CategoryState } from './category.entity';
import { categoryReducer } from './category.reducer';

export const CATEGORY_FEATURE_NAME: string = 'categories';

export const selectCategoryState = createFeatureSelector<CategoryState>(CATEGORY_FEATURE_NAME);

/*export const categoryFeature = createFeature({
	name: CATEGORY_FEATURE_NAME,
	reducer: categoryReducer
});

*/