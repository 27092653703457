<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<form [formGroup]="componentForm" (ngSubmit)="emitSubmit()" class="flex-col flex-justify-center form-container" #ngForm="ngForm">
    <div class="flex-col flex-justify-center flex-align-start form-group personal-infos-form-group" formGroupName="personalInfos" *ngIf="showPersonal">
        <div class="flex-row flex-justify-space-around form-group--row from-group--row-wrapper mail-password-wrapper" *ngIf="showEmail">
            <mat-form-field id="emailField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.email' | translate }}</mat-label>
                <input type="email" matInput placeholder="{{ 'dottnet.label.email' | translate }}" formControlName="email" />
                <mat-hint *ngIf="user">{{ 'dottnet.hint.email' | translate }}</mat-hint>
                <mat-error>{{ errorMessage('personalInfos.email') }}</mat-error>
            </mat-form-field>
            <button type="button" id="changePasswordButton" *ngIf="user && !showPwd" (click)="changePassword()" mat-raised-button color="accent">
				{{ 'dottnet.button.changePassword' | translate }}
			</button>
            <mat-form-field id="passwordField" *ngIf="!user || showPwd" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.password' | translate }}</mat-label>
                <input type="password" matInput placeholder="{{ 'dottnet.label.password' | translate }}" formControlName="password" />
                <mat-hint *ngIf="!user || showPwd">{{ 'dottnet.hint.password' | translate }}</mat-hint>
                <mat-error>{{ errorMessage('personalInfos.password') }}</mat-error>
            </mat-form-field>
        </div>
        <h3 class="form-group-title" id="anagraficaGroup">
            {{ 'dottnet.wall.anagrafica' | translate }}
        </h3>
        <hr class="form-hr" />
        <div class="flex-row form-group--row name-surname-wrapper from-group--row-wrapper">
            <mat-form-field id="nameField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.name' | translate }}</mat-label>
                <input type="text" matInput placeholder="{{ 'dottnet.label.name' | translate }}" formControlName="name" color="primary" />
                <mat-error>{{ errorMessage('personalInfos.name') }}</mat-error>
            </mat-form-field>
            <mat-form-field id="surnameField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.surname' | translate }}</mat-label>
                <input type="text" matInput placeholder="{{ 'dottnet.label.surname' | translate }}" formControlName="surname" />
                <mat-error>{{ errorMessage('personalInfos.surname') }}</mat-error>
            </mat-form-field>
        </div>

        <div class="flex-row form-group--row from-group--row-wrapper gender-birthdate-wrapper">
            <mat-radio-group id="genderRadioGroup" class="flex-col flex-row-100 flex-justify-center flex-align-center form-radios mobile-form-field" formControlName="gender">
                <mat-label>{{ 'dottnet.label.gender' | translate }}</mat-label>
                <div class="flex-row radio-gender-wrapper">
                    <mat-radio-button value="M">
                        {{ 'dottnet.label.gender.M' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="F">
                        {{ 'dottnet.label.gender.F' | translate }}
                    </mat-radio-button>
                </div>
                <mat-error *ngIf="ngForm.submitted">{{ errorMessage('personalInfos.gender') }}</mat-error>
            </mat-radio-group>
            <mat-form-field id="birthdateField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.birthdate' | translate }}</mat-label>
                <input matInput [matDatepicker]="datePicker" placeholder="{{ 'dottnet.label.birthdate' | translate }}" formControlName="birthdate" />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error>{{ errorMessage('personalInfos.birthdate') }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-row form-group--row from-group--row-wrapper birthplace-wrapper">
            <mat-radio-group id="bornAbroadRadioGroup" class="flex-col flex-row-100 flex-justify-center flex-align-center form-radios mobile-form-field" formControlName="bornAbroad" (change)="manageBirthplaceField()">
                <mat-label>{{ 'dottnet.label.birthplace.question' | translate }}</mat-label>
                <div class="flex-row birthplace-question-group">
                    <mat-radio-button value="1">
                        {{ 'dottnet.label.yes' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="0">
                        {{ 'dottnet.label.no' | translate }}
                    </mat-radio-button>
                </div>
                <mat-error *ngIf="ngForm.submitted">{{ errorMessage('personalInfos.bornAbroad') }}
                </mat-error>
            </mat-radio-group>
            <mat-form-field id="birthplaceField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.birthplace' | translate }}</mat-label>
                <input type="text" placeholder="{{ 'dottnet.label.birthplace' | translate }}" matInput [formControl]="birthplace" (input)="onPlaceChange($event.target.value)" [matAutocomplete]="autoCity" />
                <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete" [displayWith]="displayCity.bind(this)">
                    <mat-option *ngFor="let option of cityOptions$ | async" [value]="option">
                        {{ option.descrizioneComune }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>{{ errorMessage('personalInfos.birthplace') }}</mat-error>
            </mat-form-field>
        </div>
        <div class="flex-row flex-justify-center flex-align-center form-group--row codice-fiscale-wrapper">
            <mat-form-field id="codiceFiscale" class="flex-row-100 mobile-form-field" [ngClass]="{'readonly-field': bornAbroad.value === '0' || bornAbroad.value === null}">
                <mat-label>{{ 'dottnet.label.codiceFiscale' | translate }}</mat-label>
                <input type="text" matInput placeholder="{{ 'dottnet.label.codiceFiscale' | translate }}" formControlName="codiceFiscale" [readonly]="bornAbroad.value === '0' || bornAbroad.value === null" />
                <mat-error>{{ errorMessage('personalInfos.codiceFiscale') }}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-col flex-justify-center flex-align-start form-group professional-infos-form-group" formGroupName="professionalInfos" *ngIf="showProfessional">
        <h3 class="form-group-title">
            {{ 'dottnet.label.professionalInfos' | translate }}
        </h3>
        <hr class="form-hr" />
        <div class="flex-row form-group--row from-group--row-wrapper category-specialty-wrapper">
            <mat-form-field id="categoryField" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.category' | translate }}</mat-label>
                <input type="text" placeholder="{{ 'dottnet.label.category' | translate }}" matInput [formControl]="category" [matAutocomplete]="autoCategory" />
                <mat-autocomplete autoActiveFirstOption #autoCategory="matAutocomplete" [displayWith]="displayCategory.bind(this)" (optionSelected)="manageCategoryFields()">
                    <mat-option *ngFor="let option of categoryFilteredOptions$ | async" [value]="option">
                        {{ option.descrizioneCategoria }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>{{ errorMessage('professionalInfos.category') }}</mat-error>
            </mat-form-field>

            <mat-form-field id="specialtyField" *ngIf="isDoctorSelected()" class="flex-row-100 mobile-form-field">
                <mat-label>{{ 'dottnet.label.specialty' | translate }}</mat-label>
                <input type="text" placeholder="{{ 'dottnet.label.specialty' | translate }}" matInput [formControl]="specialty" [matAutocomplete]="autoSpecialty" />
                <mat-autocomplete autoActiveFirstOption #autoSpecialty="matAutocomplete" [displayWith]="displaySpecialty.bind(this)">
                    <mat-option *ngFor="let option of specialtyFilteredOptions$ | async" [value]="option">
                        {{ option.descrizioneSpecializzazione }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>{{ errorMessage('professionalInfos.specialty') }}</mat-error>
            </mat-form-field>
        </div>
        <div class="form-group flex-col flex-justify-center flex-align-start" formGroupName="registerForm" *ngIf="areShownCategoryFields()">
            <div class="flex-row form-group--row from-group--row-wrapper register-number-city-wrapper">
                <mat-form-field id="registerNumberField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.registerNumber' | translate }}</mat-label>
                    <input type="text" matInput placeholder="{{ 'dottnet.label.registerNumber' | translate }}" formControlName="registerNumber" />
                    <mat-error>{{ errorMessage('professionalInfos.registerForm.registerNumber') }}</mat-error>
                </mat-form-field>
                <mat-form-field id="registerCityField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.registerCity' | translate }}</mat-label>
                    <input type="text" placeholder="{{ 'dottnet.label.registerCity' | translate }}" matInput [formControl]="registerProvince" [matAutocomplete]="autoProvince" />
                    <mat-autocomplete autoActiveFirstOption #autoProvince="matAutocomplete" [displayWith]="displayProvince.bind(this)">
                        <mat-option *ngFor="let option of provinceFilteredOptions$ | async" [value]="option">
                            {{ option.descrizioneProvincia }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="componentForm.invalid && componentForm.touched">
                        {{ errorMessage('professionalInfos.registerForm.registerProvince') }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="form-group flex-col flex-justify-center flex-align-start" formGroupName="ecmInfos" id="ecm" *ngIf="showEcm">
        <div>
            <mat-checkbox (change)="setEcmChecked()" class="check-box" [checked]="ecmChecked">{{ 'dottnet.label.ecm' | translate }} </mat-checkbox>
        </div>
        <div *ngIf="ecmChecked" class="form-group flex-col flex-justify-center flex-align-start">
            <h3 class="form-group-title">
                {{ 'dottnet.label.ecmInfos' | translate }}
            </h3>
            <hr class="form-hr" />
            <div class="flex-row form-group--row from-group--row-wrapper ecm-wrapper">
                <mat-form-field id="cellulareField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.cellulare' | translate }}</mat-label>
                    <input type="text" placeholder="{{ 'dottnet.label.cellulare' | translate }}" matInput formControlName="cellulare" />
                    <mat-error>{{ errorMessage('ecmInfos.cellulare') }}</mat-error>
                </mat-form-field>
                <mat-form-field id="capField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.cap' | translate }}</mat-label>
                    <input type="text" placeholder="{{ 'dottnet.label.cap' | translate }}" matInput formControlName="cap" />
                    <mat-error>{{ errorMessage('ecmInfos.cap') }}</mat-error>
                </mat-form-field>
            </div>
            <div class="flex-row form-group--row from-group--row-wrapper professionType-wrapper">
                <mat-form-field id="professionplaceField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.professionplace' | translate }}</mat-label>
                    <input type="text" placeholder="{{ 'dottnet.label.professionplace' | translate }}" matInput [formControl]="professionPlace" (input)="onPlaceChange($event.target.value)" [matAutocomplete]="autoProfessionCity" />
                    <mat-autocomplete autoActiveFirstOption #autoProfessionCity="matAutocomplete" [displayWith]="displayCity.bind(this)">
                        <mat-option *ngFor="let option of cityOptions$ | async" [value]="option">
                            {{ option.descrizioneComune }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>{{ errorMessage('ecmInfos.professionPlace') }}</mat-error>
                </mat-form-field>

                <mat-form-field id="professiontypeField" class="flex-row-100 mobile-form-field">
                    <mat-label>{{ 'dottnet.label.professionType' | translate }}</mat-label>
                    <input type="text" placeholder="{{ 'dottnet.label.professionType' | translate }}" matInput [formControl]="professionType" [matAutocomplete]="autoProfessionType" />
                    <mat-autocomplete autoActiveFirstOption #autoProfessionType="matAutocomplete" [displayWith]="displayProfessionType.bind(this)">
                        <mat-option *ngFor="let option of professionTypesOptions$ | async" [value]="option">
                            {{ option.descrizioneTipoProfessione }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>{{ errorMessage('ecmInfos.professionPlace') }}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex-col flex-justify-center flex-align-start form-group consents-from-group" formGroupName="consentsForm" *ngIf="showConsents" id="consensi">
        <h3 class="form-group-title flex-row flex-justify-center">
            {{ 'dottnet.wall.consensi' | translate }}
        </h3>
        <hr class="form-hr" />
        <h4>
            {{ 'dottnet.userform.intro' | translate }}
            <a [href]="imprintUrl" target="_blank">{{ 'dottnet.userform.condition' | translate }}</a
			>. {{ 'dottnet.userform.intro2' | translate }}
			<a [href]="privacyPolicyUrl" target="_blank">{{ 'dottnet.userform.privacy' | translate }}</a> {{ 'dottnet.userform.intro3' | translate }}
        </h4>
        <div class="flex-row flex-justify-space-between flex-align-center form-group--radio-wrapper consents-row-wrapper">
            <mat-label class="flex-row flex-justify-start flex-align-center consents-label">
                {{ 'dottnet.label.consents.marketing' | translate }}
                <mat-icon (click)="openDialog('marketing')"> info_outline</mat-icon>
            </mat-label>
            <mat-radio-group class="consents-options" formControlName="marketing" id="mktRadioGroup">
                <mat-radio-button value="1">{{ 'dottnet.consents.accept' | translate }}</mat-radio-button>
                <mat-radio-button value="0">{{ 'dottnet.consents.reject' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="flex-row flex-justify-space-between flex-align-center form-group--radio-wrapper consents-row-wrapper">
            <mat-label class="flex-row flex-justify-start flex-align-center consents-label">{{ 'dottnet.label.consents.profiling' | translate }}
                <mat-icon (click)="openDialog('profiling')"> info_outline</mat-icon>
            </mat-label>
            <mat-radio-group class="consents-options" formControlName="profiling" id="prfRadioGroup">
                <mat-radio-button value="1">{{ 'dottnet.consents.accept' | translate }}</mat-radio-button>
                <mat-radio-button value="0">{{ 'dottnet.consents.reject' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="flex-row flex-justify-space-between flex-align-center form-group--radio-wrapper consents-row-wrapper">
            <mat-label class="flex-row flex-justify-start flex-align-center consents-label">{{ 'dottnet.label.consents.marketingThirdParties' | translate }}
                <mat-icon (click)="openDialog('marketingThirdParties')">info_outline</mat-icon>
            </mat-label>
            <mat-radio-group class="consents-options" formControlName="marketingThirdParties" id="mktTrzRadioGroup">
                <mat-radio-button value="1">{{ 'dottnet.consents.accept' | translate }}</mat-radio-button>
                <mat-radio-button value="0">{{ 'dottnet.consents.reject' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="flex-row flex-justify-space-between flex-align-center form-group--radio-wrapper consents-row-wrapper">
            <mat-label class="flex-row flex-justify-start flex-align-center consents-label">{{ 'dottnet.label.consents.profilingThirdParties' | translate }}
                <mat-icon (click)="openDialog('profilingThirdParties')"> info_outline</mat-icon>
            </mat-label>
            <mat-radio-group class="consents-options" formControlName="profilingThirdParties" id="prfTrzRadioGroup">
                <mat-radio-button value="1">{{ 'dottnet.consents.accept' | translate }}</mat-radio-button>
                <mat-radio-button value="0">{{ 'dottnet.consents.reject' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-error *ngIf="ngForm.submitted && !consentsForm.valid">{{ errorMessage('consentsForm') }}
        </mat-error>
    </div>
    <div class="flex-row flex-justify-end form-button-container">
        <button mat-raised-button color="accent" type="submit" [disabled]="isSubmitting || (!componentForm.dirty && (showPersonal || showProfessional || ecmChecked))">
			{{ 'dottnet.button.save' | translate }}
		</button>
    </div>
</form>