import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { categorySelectors, CategoryState } from './category.entity';
import { selectCategoryState } from './category.state';

export const selectCategory = createSelector(selectCategoryState, categorySelectors.selectAll);

export const selectCategoryError = createSelector(
	selectCategoryState,
	(state: CategoryState): ErrorMessage => state.error
);
