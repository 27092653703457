import { createFeature, createFeatureSelector } from '@ngrx/store';
import { SpecialtyState } from './specialty.entity';
import { specialtyReducer } from './specialty.reducer';

export const SPECIALTY_FEATURE_NAME: string = 'specialties';

export const selectSpecialtyState = createFeatureSelector<SpecialtyState>(SPECIALTY_FEATURE_NAME);

export const specialtyFeature = createFeature({
    name: SPECIALTY_FEATURE_NAME,
    reducer: specialtyReducer,

})