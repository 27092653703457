import { createFeature, createFeatureSelector } from '@ngrx/store';
import { ProvinceState } from './province.entity';
import { provinceReducer } from './province.reducer';

export const PROVINCE_FEATURE_NAME: string = 'provinces';

export const selectProvinceState = createFeatureSelector<ProvinceState>(PROVINCE_FEATURE_NAME);

export const provinceFeature = createFeature({
    name: PROVINCE_FEATURE_NAME,
    reducer: provinceReducer
})